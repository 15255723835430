import React, { memo } from "react";
import styles from "../styles/components/shortInput.module.scss";

const Checklist = ({
  items,
  placeholder,
  checkedItems,
  setCheckedItems,
  required = false,
}) => {
  const handleCheck = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };
  return (
    <div className={styles.shortInputContainer}>
      <label>{placeholder + " :"}</label>
      {required && <span className={styles.requiredMark}> *</span>}
      <ul style={{ listStyleType: "none" }}>
        {items.map((item, index) => (
          <li key={index}>
            {!item?.username && (
              <input
                type="checkbox"
                value={item}
                onChange={handleCheck}
                checked={checkedItems.includes(item)}
              />
            )}
            <span> {item?.username ? item.username : item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(Checklist);
