import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/AdminsTable";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";
import DropdownSelect from "../components/DropdownSelect";

export default function FilterAdmins({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
  });

  const [tableData, setTableData] = useState({
    th: ["Username", "Email", "type", "Edit"],
    td: [],
  });

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleNameChange = (e) => {
    if (!editRow) return;
    editRow.name = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEmailChange = (e) => {
    if (!editRow) return;
    editRow.email = e.target.value;
    setEditRow({ ...editRow });
  };

  const handleTypeChange = (e) => {
    if (!editRow) return;
    editRow.type = e.target.value;
    setEditRow({ ...editRow });
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      email: editRow.email,
      name: editRow.name,
      id: editRow.id,
      type: editRow.type,
    };
    let res = await api.updateAdmin(data);
    if (res?.user) {
      setNotification({
        type: "success",
        text: "Admin updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this admin?") === true
    ) {
      setLoading(true);
      const res = await api.deleteAdmins({ id: editRow.id });
      if (!res) return setLoading(false);
      if (res !== "error") {
        setUpdateTrigger(!updateTrigger);
        setNotification({
          ...{ text: "deleted successfully", type: "success" },
        });
        setLoading(false);
        return;
      } else {
        setNotification({
          ...{ text: "Something went wrong", type: "error" },
        });
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await api.getAdminData(tableOptions);
      setCountTotal(data.totalCount);
      tableData.td = [];
      data.admins.map((admin, index) => {
        tableData.td.push({
          id: admin._id,
          checked: false,
          username: admin.username,
          email: admin.email,
          type: admin.type,
          button: { action: buttonAction, text: "Edit" },
        });
      });
      setLoading(false);
      console.log(tableData.td);
      setTableData({ ...tableData });
    };
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        email: filterEmail,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Admins</span>
        </div>
        <div className={styles.cardDropDowns}>
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="Username"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.email}
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                handleTypeChange={handleTypeChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({
  editRow,
  handleNameChange,
  handleEmailChange,
  handleTypeChange,
}) {
  return (
    <div className={styles.containerWrapper}>
      <ShortInput
        defaultValue={editRow?.username}
        placeholder="Name"
        icon={userIcon}
        action={handleNameChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.email}
        placeholder="Email"
        icon={emailIcon}
        action={handleEmailChange}
        disabled={true}
      />
      <DropdownSelect
        defaultValue={{
          text: editRow?.type,
          value: editRow.type,
        }}
        placeholder="SUPER_ADMIN"
        action={handleTypeChange}
        options={[
          {
            text: "SUPER_ADMIN",
            value: "SUPER_ADMIN",
          },
          {
            text: "ADMIN",
            value: "ADMIN",
          },
          {
            text: "MODERATOR",
            value: "MODERATOR",
          },
          {
            text: "SUPPORT",
            value: "SUPPORT",
          },
        ]}
      />
    </div>
  );
}
