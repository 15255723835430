import React, { useState, useEffect, memo } from "react";
import styles from "../styles/components/topNav.module.scss";
import TextInput from "./TextInput";
import profileIcon from "../assets/images/logo.png";

const TopNav = ({ handleSidebarOpen }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      let userObj = JSON.parse(user);
      setRole(userObj.type);
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.topNav}>
      {isMobile && (
        <button className={styles.menuButton} onClick={handleSidebarOpen}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
      )}
      <div className={styles.searchboxWrapper}>
        <TextInput
          placeholder={"Search"}
          onChange={() => true}
          defaultValue={""}
        />
      </div>
      <span style={{ font: "-moz-initial", color: "black", width: "15%" }}>
        ROLE_{`${role}`}
      </span>
      <button
        className={styles.topNavButton}
        style={{ background: `url('${profileIcon}')` }}
      />
    </div>
  );
};
export default memo(TopNav);
