import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import LoadingAnim from "../components/LoadingAnim";
import TextAreaInput from "../components/TextAreaInput";
import api from "../api/api";

export default function AnnouncementList({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterAudience, setFilterAudience] = useState("DUEL_CUBE");
  const [filterSender, setFilterSender] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    sender: "",
    audience: "",
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these announcements?"))
      return;
    // let checked = tableData?.td.filter((td) => td.checked);
    // setLoading(true);
    // let res = await users.deleteBulk(checked);
    // if (res.message.includes("Deleted")) {
    //   setNotification({
    //     type: "success",
    //     text: "Users deleted successfully",
    //   });
    //   setEditRow(null);
    //   setLoading(false);
    //   setUpdateTrigger(!updateTrigger);
    //   return;
    // }
    // setNotification({
    //   type: "error",
    //   text: "Something went wrong",
    // });
    // setEditRow(null);
    // setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: ["Audience", "Message", "Sent", "Date", "Action", ""],
    td: [],
  });

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleFilterSenderChange = (e) => {
    setFilterSender(e.target.value);
  };
  const handleFilterAudienceChange = (e) => {
    setFilterAudience(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let res = await api.processPayout(editRow.id);
    if (res.message) {
      setNotification({
        type: "success",
        text: "Announcement updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this announcement?") ===
      true
    ) {
      setLoading(true);
      let res = await api.deleteAnnouncement(editRow.id);
      if (res !== "error") {
        setNotification({
          type: "success",
          text: "Announcement deleted successfully",
        }); 
        setUpdateTrigger(!updateTrigger);
        setEditRow(null);
        setLoading(false);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await api.getAnnouncementData(tableOptions);
      setCountTotal(data.totalCount);
      tableData.td = [];
      data.Announcements.map((item, index) => {
        return tableData.td.push({
          id: item?._id,
          checked: false,
          audience: item?.audience,
          message: item?.body,
          sent: item?.isSend ? "Sent" : "Not Sent",
          date: new Date(item?.date).toLocaleString(),
          button: { action: buttonAction, text: "View" },
          sender: item?.sender,
          title: item?.title,
          category: item?.category,
        });
      });
      setLoading(false);
      setTableData({ ...tableData });
    };
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        sender: filterSender,
        audience: filterAudience,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterSender, filterAudience]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Announcement List</span>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={"DUEL_CUBE"}
            placeholder="Audience"
            action={handleFilterAudienceChange}
            disabled={true}
          />
          <ShortInput placeholder="Sender" action={handleFilterSenderChange} />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={<Filters editRow={editRow} />}
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
            notEditable
            notSelection
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow, handleNameChange, handleEmailChange }) {
  return (
    <div className={styles.cardDropDowns}>
      <ShortInput
        defaultValue={editRow?.title}
        placeholder="Topic"
        action={handleNameChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.audience}
        placeholder="Audience"
        action={handleEmailChange}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.category}
        placeholder="Category"
        action={handleEmailChange}
        disabled={true}
      />
      <TextAreaInput
        defaultValue={editRow?.message}
        disabled={true}
        placeholder={"message"}
        label={"Message : "}
        customStyles={{
          height: "auto",
          alignItems: "flex-start",
          marginTop: "10px",
          margin: "30px",
        }}
        inputStyles={{
          minHeight: "80px",
        }}
        rows={8}
      />
    </div>
  );
}
