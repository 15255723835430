import React from "react";
import styles from "../styles/components/newTable.module.scss";
import SmallButton from "../components/SmallButton";

export function TableRow({ tr, th, handleClick, handleLive }) {
  return (
    <tr className={styles.tr} key={tr}>
      {Object.keys(tr).map((key, index) => (
        <>
          {th.length != index && (
            <td key={index} className={styles.td}>
              {tr[key]?.split("/")[0] === "Streamer" ? (
                <span
                  onClick={() =>
                    handleLive({
                      id: tr[key]?.split("/")[1]?.split("&")[0],
                      type: tr[key]?.split("&")[1],
                    })
                  }
                  style={
                    tr[key]?.split("/")[1]?.split("&")[0] !== "No Live"
                      ? {
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }
                      : {}
                  }
                >
                  {tr[key]?.split("/")[1]?.split("&")[0] === "No Live"
                    ? "No Live"
                    : "Click Here"}
                </span>
              ) : tr[key] === "Refund" ? (
                <SmallButton
                  type="primary"
                  text={"Refund"}
                  onClick={() => handleClick(tr)}
                />
              ) : (
                tr[key]
              )}
            </td>
          )}
        </>
      ))}
    </tr>
  );
}

export default function NewTable({
  tableData,
  setTableData,
  handleClick,
  handleLive,
}) {
  return (
    <div className={styles.tableContainer}>
      <table>
        <thead className={styles.tr}>
          <tr>
            {tableData.th.map((th, index) => (
              <th
                key={index}
                className={
                  index === 1
                    ? `${styles.longThUserTable} ${styles.th}`
                    : styles.th
                }
              >
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.td.map((tr, index) => (
            <TableRow
              tr={tr}
              th={tableData.th}
              handleClick={handleClick}
              handleLive={handleLive}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
