import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import api from "../api/api";

import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";

import emailIcon from "../assets/images/emailIcon.svg";
import userIcon from "../assets/images/userIcon.svg";
import LoadingAnim from "../components/LoadingAnim";
import DropdownSelect from "../components/DropdownSelect";

export default function AddAdmin({ updateTrigger, setUpdateTrigger }) {
  const [username, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(null);

  const handleSave = async () => {
    if (!username || !email || !type) return;
    setLoading(true);
    let data = {
      username,
      email,
      type,
    };
    const res = await api.createAdmin(data);
    if (!res) return setLoading(false);
    if (res.message) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Admin added successfully", type: "success" },
      });
      setLoading(false);
      return;
    }
    if (!res.success) {
      setNotification({ ...{ text: res.message, type: "error" } });
      setLoading(false);
      return;
    }
    setNotification({ ...{ text: "Something went wrong", type: "error" } });
    setLoading(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Admin</span>
      </div>
      <div className={styles.containerWrapper}>
        <ShortInput
          action={handleNameChange}
          placeholder="Username"
          icon={userIcon}
        />
        <ShortInput
          action={handleEmailChange}
          placeholder="Email"
          icon={emailIcon}
          type="email"
        />
        <DropdownSelect
          defaultValue={{
            text: "SUPER_ADMIN",
            value: "SUPER_ADMIN",
          }}
          placeholder="SUPER_ADMIN"
          action={handleTypeChange}
          options={[
            {
              text: "SUPER_ADMIN",
              value: "SUPER_ADMIN",
            },
            {
              text: "ADMIN",
              value: "ADMIN",
            },
            {
              text: "MODERATOR",
              value: "MODERATOR",
            },
            {
              text: "SUPPORT",
              value: "SUPPORT",
            },
          ]}
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
