import React, { useState, useEffect } from "react";
import styles from "../styles/components/notification.module.scss";

export default function Notification({ data }) {
  const [containerClass, setContainerClass] = useState(
    data
      ? data.type === "success"
        ? `${styles.success} ${styles.notificationContainer} ${styles.show}`
        : data.type === "error"
        ? `${styles.error} ${styles.notificationContainer} ${styles.show}`
        : data.type === "decline"
        ? `${styles.decline} ${styles.notificationContainer} ${styles.show}`
        : ""
      : ""
  );

  useEffect(() => {
    let newClass = "";
    if (data) {
      if (data.type === "success") {
        newClass = `${styles.success} ${styles.notificationContainer} ${styles.show}`;
      } else if (data.type === "error") {
        newClass = `${styles.error} ${styles.notificationContainer} ${styles.show}`;
      } else if (data.type === "decline") {
        newClass = `${styles.decline} ${styles.notificationContainer} ${styles.show}`;
      }
    }
    setContainerClass(newClass);
    let timer = setTimeout(() => {
      if (data) {
        if (data.type === "success") {
          newClass = `${styles.success} ${styles.notificationContainer} ${styles.hide}`;
        } else if (data.type === "error") {
          newClass = `${styles.error} ${styles.notificationContainer} ${styles.hide}`;
        } else if (data.type === "decline") {
          newClass = `${styles.decline} ${styles.notificationContainer} ${styles.hide}`;
        }
      }
      setContainerClass(newClass);
    }, 5000);
    return () => clearTimeout(timer);
  }, [data]);

  useEffect(() => {
    setContainerClass(
      data
        ? data.type === "success"
          ? `${styles.success} ${styles.notificationContainer} ${styles.show}`
          : data.type === "error"
          ? `${styles.error} ${styles.notificationContainer} ${styles.show}`
          : data.type === "decline"
          ? `${styles.decline} ${styles.notificationContainer} ${styles.show}`
          : ""
        : ""
    );
  }, [data]);

  if (data) {
    return (
      <div className={containerClass}>
        <div className={styles.notificationText}>{data.text}</div>
      </div>
    );
  } else {
    return <></>;
  }
}
