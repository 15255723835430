import React, { useState, useEffect, useCallback } from "react";
import styles from "../styles/cards/category.module.scss";
import Pagination from "../components/Pagination";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import api from "../api/api";
import CategoryCard from "../components/CategoryCard";
import AddCategory from "./AddCategory";
import Notification from "../components/Notification";
import SmallButton from "../components/SmallButton";

export default function Categories() {
  const [loading, setLoading] = useState(false);
  const [countTotal, setCountTotal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [notification, setNotification] = useState(null);

  const toggleSelect = useCallback(
    (productId) => {
      const isSelected = selectedCategories.includes(productId);
      if (isSelected) {
        setSelectedCategories(
          selectedCategories.filter((id) => id !== productId)
        );
      } else {
        setSelectedCategories([...selectedCategories, productId]);
      }
    },
    [selectedCategories]
  );

  const handleDelete = async () => {
    const blackList = categories.filter((categoryObj) =>
      selectedCategories.includes(categoryObj._id)
    );
    setLoading(true);
    console.log(blackList);
    const res = await api.deleteProductCategory({ categories: blackList });
    if (!res) return setLoading(false);
    if (res.status === 200) {
      setNotification({
        ...{ text: res.data.message, type: "success" },
      });
      setIsUpdate(!isUpdate);
      setLoading(false);
      setSelectedCategories([]);
      return;
    }
    setNotification({ ...{ text: res.data.message, type: "error" } });
    setLoading(false);
    setSelectedCategories([]);
  };

  const toggleAddCategory = () => {
    setIsVisible((prev) => !prev);
  };

  const options = [
    {
      text: "Add Category",
      action: toggleAddCategory,
    },
  ];
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await api.getProductCategory(tableOptions);
      setCountTotal(data.totalCount);
      setCategories(data.categories);
      setLoading(false);
    };
    getData();
  }, [tableOptions, isUpdate]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Product Categories</span>
        </div>
      </div>
      <div className={styles.formContainer}>
        {isVisible && (
          <AddCategory
            setIsVisible={setIsVisible}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          />
        )}
      </div>
      <div className={styles.cardWrapper}>
        <LoadingAnim loading={loading} />
        {categories.map((category) => {
          return (
            <CategoryCard
              key={category._id}
              category={category}
              onSelect={toggleSelect}
              isSelected={selectedCategories.includes(category._id)}
            />
          );
        })}
      </div>
      {selectedCategories.length !== 0 && (
        <div className={styles.buttonWrapper}>
          <SmallButton
            type="danger"
            text="Delete Selected"
            onClick={() => handleDelete()}
          />
        </div>
      )}
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
