import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import userIcon from "../assets/images/userIcon.svg";
import LoadingAnim from "../components/LoadingAnim";
import api from "../api/api";
import Checklist from "../components/Checklist";
import SubChecklist from "../components/SubChecklist";
import Switch from "react-switch";
import ImageUpload from "../components/ImageUpload";

export default function AddGame({ updateTrigger, setUpdateTrigger }) {
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [imageData, setImageData] = useState({});
  const maxFileSizeMB = 5;
  const supportedFormats = ["jpeg", "png", "jpg", "webp"];

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const [notification, setNotification] = useState(null);

  const [selectedConsoles, setSelectedConsoles] = useState([]);
  const consoles = [
    "CROSS_PLAY",
    "CURRENT_GEN_CROSS_PLAY",
    "LAST_GEN_CROSS_PLAY",
    "PC",
    "CONSOLE_ONLY",
    "PS4",
    "PS5",
    "XBOX_ONE",
    "XBOX_X_S",
  ];

  const [selectedGameTypes, setSelectedGameTypes] = useState([]);
  const gameTypes = [
    "ONE_VS_ONE",
    "TWO_VS_TWO",
    "THREE_VS_THREE",
    "FOUR_VS_FOUR",
    "FIVE_VS_FIVE",
    "FFA",
  ];
  const [selectedRounds, setSelectedRounds] = useState([]);
  const rounds = [
    "BEST_1_OF_1",
    "BEST_1_OF_2",
    "BEST_2_OF_3",
    "BEST_3_OF_5",
    "BEST_5_OF_7",
  ];

  const teams = [
    "BR Kill Race",
    "Arena Kill Race",
    "BR Kill Challenge",
    "CDL Hardpoint",
    "CDLS & D",
    "Death Match",
    "Gunfight",
    "Snipers only",
    "BR Survival",
    "Resurgence Kill race",
    "Resurgence Kill Challenge",
    "Regular Team",
    "MUT",
    "RegularTeams",
    "FUT",
    "Kill Race",
    "Zero Build",
    "Box Fight",
    "Zone Wars",
  ];
  const [selectedProviders, setSelectedProviders] = useState([]);

  const providers = [
    "EPIC",
    "ACTIVISION",
    "ORIGIN",
    "STEAM",
    "PSN",
    "XBL",
    "NINTENDO",
    "BATTLE_NET",
    "EA",
    "STREET_FIGHTER",
    "MLB",
  ];
  const handleSave = async () => {
    if (
      !name &&
      Object.keys(imageData).length === 0 &&
      !selectedProviders.length > 0 &&
      !selectedConsoles.length > 0 &&
      !selectedGameTypes.length > 0 &&
      !selectedRounds.length > 0
    )
      return setNotification({
        ...{ text: "Please fill all fields", type: "error" },
      });
    setLoading(true);
    let data = {
      name,
      providers: selectedProviders,
      consoles: selectedConsoles,
      rounds: selectedRounds,
      gameTypes: selectedGameTypes.map((item) => ({
        type: item.item,
        teams: item.subItems,
      })),
      streamRequired: checked,
      file: imageData,
    };
    const res = await api.addGame(data);
    if (!res) return setLoading(false);
    resetData();
    if (res !== "error") {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Game aded successfully", type: "success" },
      });
      setLoading(false);
      return;
    } else {
      setNotification({ ...{ text: "Something went wrong", type: "error" } });
      setLoading(false);
      return;
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleImageChange = (data) => {
    setImageData(data);
  };

  const resetData = () => {
    setName("");
    setChecked(false);
    setImageData({});
    setSelectedConsoles([]);
    setSelectedProviders([]);
    setSelectedGameTypes([]);
    setSelectedRounds([]);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Game</span>
      </div>
      <div className={styles.cardDropDowns}>
        <div className={styles.column}>
          <ShortInput
            defaultValue={name}
            action={handleNameChange}
            placeholder="Name"
            icon={userIcon}
            required
          />
          <Checklist
            items={providers}
            placeholder="Provider Types"
            checkedItems={selectedProviders}
            setCheckedItems={setSelectedProviders}
            required
          />
        </div>
        <div className={styles.column}>
          <Checklist
            items={consoles}
            placeholder="Consoles"
            checkedItems={selectedConsoles}
            setCheckedItems={setSelectedConsoles}
            required
          />
          <SubChecklist
            items={gameTypes}
            placeholder="Game Types"
            checkedItems={selectedGameTypes}
            setCheckedItems={setSelectedGameTypes}
            subItems={teams}
            subPlaceholder="Teams"
            required
          />
          <Checklist
            items={rounds}
            placeholder="Rounds"
            checkedItems={selectedRounds}
            setCheckedItems={setSelectedRounds}
            required
          />
          <div className={styles.toggle}>
            <span style={{ color: "black" }}>Stream Required :</span>
            <Switch
              checked={checked}
              onChange={handleChange}
              onColor="#f7a907"
              offColor="#ccc"
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <span className={styles.trackingText}>
            Enable stream when your game unavailable in the tracking site
          </span>
          <div className={styles.imageUpload}>
            <ImageUpload
              imageUrl={imageData}
              placeholder="Game Image"
              action={handleImageChange}
              required
            />
          </div>
          <div className={styles.fileInfo}>
            <p>
              <strong>Supported Formats:</strong> {supportedFormats.join(", ")}
            </p>
            <p>
              <strong>Max File Size:</strong> {maxFileSizeMB} MB
            </p>
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
