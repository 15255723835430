import React, { useState, useEffect, useCallback } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";
import api from "../api/api";
import DatePicker from "../components/DatePicker";

export default function Transactions({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterType, setFilterType] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    type: "",
  });

  const [tableData, setTableData] = useState({
    th: ["Select", "ID", "UserID", "UserName", "Date", "Amount", "Type", "Action"],
    td: [],
  });
  const buttonAction = (data) => {
  };
  const getTableData = async () => {
    setLoading(true);
    const data = await api.getTransactionData(tableOptions);
    if(data.type === "error"){
     setNotification({ type: "error", message: data?.message});
      setLoading(false);
      return;
    }
    setCountTotal(data.totalCount);
    tableData.td = [];
    data.transactions.map((item, index) => {
      tableData.td.push({
        id: item?._id,
        checked: false,
        ID: item?.transactionId,
        UserID: item?.user?._id,
        UserName: item.user?.username,
        Date: item?.date,
        Amount: item.amount,
        Type: item?.transactionType,
        UserID: item?.user?._id,
        button: { action: buttonAction, text: "view" },
      });
    });
    setLoading(false);
    setTableData({ ...tableData });
  };

  useEffect(() => {
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions({
        ...tableOptions,
        name: filterName,
        type: filterType,
      });
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterType]);

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Transactions</span>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            defaultValue={editRow?.name}
            placeholder="User"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            defaultValue={editRow?.type}
            placeholder="Type"
            action={handleFilterTypeChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={<Filters editRow={editRow} />}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.UserID}
        placeholder="User"
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.UserName}
        placeholder="UserName"
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.ID}
        placeholder="TransactionID"
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.Amount}
        placeholder="Amount"
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.Type}
        placeholder="Type"
        disabled={true}
      />
    </>
  );
}
