import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";
import Table from "../components/UsersTable";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";
import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";
import api from "../api/api";

export default function FilterUsers({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [editRow, setEditRow] = useState(null);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    name: "",
    email: "",
    createdDate: "",
  });

  const handleBulkDelete = async () => {
    setLoading(true);
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      let checked = tableData?.td.filter((td) => td.checked);
      console.log(checked);
      const res = await api.deleteUser({
        userIds: checked.map((item) => item.id),
      });
      if (res) {
        setNotification({
          type: "success",
          text: "User deleted.",
        });
        getTableData();
      } else {
        setNotification({
          type: "error",
          text: "Please try again..",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const [tableData, setTableData] = useState({
    th: [
      "Select",
      "Username",
      "Email",
      "Created On",
      "Phone Number",
      "DC Balance",
      "Edit",
    ],
    td: [],
  });

  const buttonAction = (data) => {
    console.log(data);
  };
  const handleNameChange = (e) => {
    console.log("name");
    if (!editRow) return;
    editRow.name = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEmailChange = (e) => {
    if (!editRow) return;
    editRow.email = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleTwitchChange = (e) => {
    if (!editRow) return;
    editRow.twitchId = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleActivitionChange = (e) => {
    if (!editRow) return;
    editRow.activisionId = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEaChange = (e) => {
    if (!editRow) return;
    editRow.eaId = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleXboxChange = (e) => {
    if (!editRow) return;
    editRow.xboxGammerTag = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEpicChange = (e) => {
    if (!editRow) return;
    editRow.epicId = e.target.value;
    setEditRow({ ...editRow });
  };
  const handlePlaystationChange = (e) => {
    if (!editRow) return;
    editRow.psnGamerTag = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleStreetFighterChange = (e) => {
    if (!editRow) return;
    editRow.streetFighterId = e.target.value;
    setEditRow({ ...editRow });
  };
  const handlePaypalChange = (e) => {
    if (!editRow) return;
    editRow.paypalMail = e.target.value;
    setEditRow({ ...editRow });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    setTableOptions((prevOptions) => ({
      ...prevOptions,
      createdDate: selectedDate,
      page: 1,
    }));
  };

  const handleFilterEmailChange = (e) => {
    setFilterEmail(e.target.value);
  };
  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  //Update user
  const handleOnSave = async () => {
    setLoading(true);
    const data = {
      userId: editRow.id,
      username: editRow.name,
      email: editRow.email,
      twitchLogin: editRow.twitchLogin,
      twitchId: editRow.twitchId,
      paypalId: editRow.paypalId,
      paypalMail: editRow.paypalMail,
      xboxGammerTag: editRow.xboxGammerTag,
      psnGamerTag: editRow.psnGamerTag,
      activisionId: editRow.activisionId,
      eaId: editRow.eaId,
      epicId: editRow.epicId,
      streetFighterId: editRow.streetFighterId,
    };
    const res = await api.updateUserData(data);

    if(res.type==='error') {
      setNotification({
        type: "error",
        text: res.message,
      });
    }
    else {
      setNotification({
        type: "success",
        text: "User updated successfully",
      });
      getTableData();
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  const getTableData = async () => {
    setLoading(true);
    const data = await api.getUserData(tableOptions);
    setCountTotal(data.totalCount);
    tableData.td = [];
    data.users.map((user, index) => {
      tableData.td.push({
        id: user._id,
        checked: false,
        username: user?.username,
        email: user?.email,
        createdAt: user?.createdAt
          ? new Date(user?.createdAt).toLocaleString().split("T")[0]
          : "N/A",
        phoneNumber: user?.phoneNumber ? user?.phoneNumber : "N/A",
        dcBalance: user?.dcBalance,
        twitchId: user?.twitchId,
        activisionId: user?.activisionId,
        eaId: user?.eaId,
        xboxGammerTag: user?.xboxGammerTag,
        psnGamerTag: user?.psnGamerTag,
        epicId: user?.epicId,
        streetFighterId: user?.streetFighterId,
        paypalMail: user?.paypalMail,
        button: { action: buttonAction, text: "Edit" },
      });
    });
    setLoading(false);
    console.log(tableData.td);
    setTableData({ ...tableData });
  };

  useEffect(() => {
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      setTableOptions((prev) => ({
        ...prev,
        name: filterName,
        email: filterEmail,
        page: 1,
      }));
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [filterName, filterEmail]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>User Details</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions
              options={options}
              openList={tableData?.td.filter((td) => td.checked)}
            />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
          <ShortInput
            placeholder="Name"
            icon={userIcon}
            action={handleFilterNameChange}
          />
          <ShortInput
            placeholder="Email"
            icon={emailIcon}
            action={handleFilterEmailChange}
          />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                handleTwitchChange={handleTwitchChange}
                handleActivitionChange={handleActivitionChange}
                handleEaChange={handleEaChange}
                handleXboxChange={handleXboxChange}
                handlePlaystationChange={handlePlaystationChange}
                handleEpicChange={handleEpicChange}
                handleStreetFighterChange={handleStreetFighterChange}
                handlePaypalChange={handlePaypalChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({
  editRow,
  handleNameChange,
  handleEmailChange,
  handleActivitionChange,
  handleEaChange,
  handleTwitchChange,
  handleXboxChange,
  handlePlaystationChange,
  handleEpicChange,
  handleStreetFighterChange,
  handlePaypalChange,
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr ",
        alignItems: "center",
        gap: 5,
        margin: 20,
      }}
    >
      <ShortInput
        defaultValue={editRow?.id}
        placeholder="ID"
        icon={userIcon}
        disabled={true}
      />
      <ShortInput
        defaultValue={editRow?.username}
        placeholder="Name"
        icon={userIcon}
        action={handleNameChange}
      />
      <ShortInput
        defaultValue={editRow?.email}
        placeholder="Email"
        icon={emailIcon}
        action={handleEmailChange}
      />

      <ShortInput
        defaultValue={editRow?.twitchId}
        placeholder="Twitch ID"
        icon={userIcon}
        action={handleTwitchChange}
      />
      <ShortInput
        defaultValue={editRow?.activisionId}
        placeholder="Activition ID"
        icon={userIcon}
        action={handleActivitionChange}
      />
      <ShortInput
        defaultValue={editRow?.eaId}
        placeholder="EA ID"
        icon={userIcon}
        action={handleEaChange}
      />
      <ShortInput
        defaultValue={editRow?.xboxGammerTag}
        placeholder="Xbox gamer tag"
        icon={userIcon}
        action={handleXboxChange}
      />
      <ShortInput
        defaultValue={editRow?.psnGamerTag}
        placeholder="Playstation ID"
        icon={userIcon}
        action={handlePlaystationChange}
      />
      <ShortInput
        defaultValue={editRow?.epicId}
        placeholder="Epic ID"
        icon={userIcon}
        action={handleEpicChange}
      />
      <ShortInput
        defaultValue={editRow?.streetFighterId}
        placeholder="Street Fighter ID"
        icon={userIcon}
        action={handleStreetFighterChange}
      />
      <ShortInput
        defaultValue={editRow?.paypalMail}
        placeholder="Paypal mail"
        icon={emailIcon}
        action={handlePaypalChange}
      />
    </div>
  );
}
