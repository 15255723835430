import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import TextAreaInput from "../components/TextAreaInput";
import api from "../api/api";
import DropdownSelect from "../components/DropdownSelect";

export default function PostAnnouncement({ updateTrigger, setUpdateTrigger }) {
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [audience, setAudience] = useState("DUEL_CUBE");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(null);

  const handleSave = async () => {
    if (!title || !body) return;
    setLoading(true);
    let data = {
      title,
      body,
      audience,
      category,
    };
    const res = await api.postAnnouncement(data);
    if (!res) return setLoading(false);
    if (res.message) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Announcement sent successfully", type: "success" },
      });
      setLoading(false);
      setTitle("");
      setBody("");
      return;
    }
    if (res === "error") {
      setNotification({
        ...{ text: res.message ?? "Something went wrong", type: "error" },
      });
      setLoading(false);
      return;
    }
    setNotification({ ...{ text: "Something went wrong", type: "error" } });
    setLoading(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleBodyDataChange = (e) => {
    setBody(e.target.value);
  };
  const handleAudienceDataChange = (e) => {
    setAudience(e.target.value);
  };
  const handleStateChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Post Announcements</span>
      </div>
      <div className={styles.containerWrapper}>
        <ShortInput
          action={handleTitleChange}
          placeholder="Title"
          defaultValue={title}
        />
        <ShortInput
          action={handleAudienceDataChange}
          placeholder="Audience"
          defaultValue={"DUEL_CUBE"}
          disabled={true}
        />
        <DropdownSelect
          defaultValue={{
            text: "All",
            value: 0,
          }}
          placeholder="Category"
          action={handleStateChange}
          options={[
            {
              text: "All",
              value: "All",
            },
            {
              text: "Events",
              value: "Events",
            },
            {
              text: "Updates",
              value: "Updates",
            },
            {
              text: "News",
              value: "News",
            },
          ]}
        />
        <TextAreaInput
          defaultValue={body}
          disabled={false}
          onChange={handleBodyDataChange}
          placeholder={"Body"}
          label={"Body : "}
          customStyles={{
            height: "auto",
            alignItems: "flex-start",
            marginTop: "10px",
            margin: "30px",
          }}
          inputStyles={{
            minHeight: "80px",
          }}
          rows={8}
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Send" onClick={handleSave} />
      </div>
    </div>
  );
}
