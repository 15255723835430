import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/components/cardOptions.module.scss";
export default function CardOptions({ options, openList = [] }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={styles.cardOptions} ref={ref}>
      <button className={styles.dotButton} onClick={() => setOpen(!open)}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </button>
      {(open || openList.length > 0) && (
        <div className={styles.optionsContainer}>
          {options.map((option, index) => (
            <button
              key={index}
              className={styles.option}
              onClick={option.action}
            >
              {option.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
